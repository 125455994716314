import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from '../authStore';
import { ApiResponseStatus, ApiSuccess, postRequest } from '~/util/ApiRequest';
import { sleep } from '~/util/sleep';
import { CommonModalHeader } from '~/components/templates/modals/common/CommonModalHeader';
import { CommonModalFooter } from '~/components/templates/modals/common/CommonModalFooter';
import { ButtonWithStatus } from '~/components/molecules/ButtonWithStatus';
import VerificationInput from 'react-verification-input';
import classNames from 'classnames';
import { LockIcon } from '~/images/LockIcon';
import { CommonModal } from '~/components/templates/modals/common/CommonModal';
import styles from '../LoginOrSignUpModal.module.scss';
import commonModalStyles from '../../../common/CommonModal.module.scss';
import { FormAlert } from '~/components/molecules/FormAlert';

interface Props {
  auth: AuthStore;
}

export const ActivationAuthStep: React.FC<React.PropsWithChildren<Props>> = observer(({ auth }) => {
  const [codeInput, setCodeInput] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [hasResentEmail, setHasResentEmail] = useState<boolean>(false);

  const resendActivationEmail = useCallback(async () => {
    if (isLoading) {
      return { status: ApiResponseStatus.Success, data: {} } as ApiSuccess;
    }

    setLoading(true);
    try {
      const response = await postRequest(`/users/verify/send`);
      await sleep(1000);
      return response;
    } finally {
      setHasResentEmail(true);
      setLoading(false);
    }
  }, [isLoading]);

  const validateCode = (code: string) => {
    setLoading(true);
    auth
      .formSubmit({ code })
      .then((response) => {
        if (response.status === ApiResponseStatus.Error) {
          setServerError(response.data || 'Unknown error');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();
    if (isLoading) return false;
    validateCode(codeInput);
    return false;
  };

  const message = (
    <div className={styles.nextSteps}>
      <p>
        Check your email for a verification code from Rosarium Health&trade;. After verification,
        you will be able to create your first job.
      </p>
    </div>
  );

  return (
    <CommonModal className={styles.modal}>
      <CommonModalHeader title="Account Activation Required" showCloseButton={false} />
      <div className={classNames(commonModalStyles.modalMainContent, styles.login)}>
        <form name="activate" id="activate" onSubmit={onSubmit}>
          <div className={styles.modalBody}>{message}</div>
          <div className={styles.verificationWrapper}>
            <VerificationInput
              autoFocus
              validChars="0-9"
              length={6}
              placeholder=""
              onComplete={(value) => {
                if (value && value.length === 6) validateCode(value);
              }}
              inputProps={{
                name: 'code',
                id: 'code',
              }}
              value={codeInput}
              onChange={(value) => {
                setCodeInput(value);
              }}
              classNames={{
                container: styles.verificationContainer,
                character: styles.verificationCharacter,
                characterInactive: styles.verificationCharacterInactive,
                characterSelected: styles.verificationCharacterSelected,
              }}
            />
          </div>
          <p>
            {hasResentEmail && (
              <button type="button" className="btn link" onClick={auth.completedStep}>
                Still haven't received the email? Skip this step.
              </button>
            )}
          </p>
          <FormAlert alert={serverError} />
        </form>
      </div>
      <CommonModalFooter className={styles.loginFooter}>
        <button type="button" className="btn" onClick={auth.onDismiss}>
          Cancel
        </button>
        <div className={styles.loginFooterButtonGroup}>
          <ButtonWithStatus className="secondary" onClick={resendActivationEmail}>
            Resend email
          </ButtonWithStatus>
          <button
            type="submit"
            form="activate"
            className={classNames('btn red', isLoading && 'loading')}
            data-testid="btn-continue"
            disabled={isLoading}
          >
            <LockIcon /> Verify Account
          </button>
        </div>
      </CommonModalFooter>
    </CommonModal>
  );
});
